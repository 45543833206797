/*
 * @名称: 接口统一访问
 * @Descripttion: ml 创建
 * @Author: ml
 * @Date: 2019-12-07 16:07:05
 * @LastEditors  : ml
 * @LastEditTime : 2020-05-12 18:17:51
 */
import axios from 'axios'
import { Message, Loading } from 'element-ui'
import { Toast } from 'vant'
import store from '../store'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // api的base_url
  timeout: 30000
})

// 添加请求拦截器
service.interceptors.request.use(config => {
  config.headers['Cache-Control'] = 'no-cache' // ie清缓存，否则无法正常刷新
  config.headers['Pragma'] = 'no-cache' // HTTP/1.1版本，ie清缓存，否则无法正常刷新

  config.headers['Authorization'] = store.state.Token

  if (config.formType && config.formType === 1) {
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded'

    config.transformRequest = [function (data) {
      let ret = ''
      for (let it in data) {
        ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
      }
      return ret.substring(0, ret.length - 1)
    }]
  }

  // if (config.showLoading) {
  //   showFullScreenLoading()
  // }

  if (config.data.secret) {
    config.headers['Authorization'] = 'Bearer ' + config.data.secret
  }

  let _publicParam = {
    LoginUserId: store.state.LoginUserId,
    LoginUserName: store.state.LoginUserName,
    LoginIp: store.state.LoginIp,
    LoginCityName: store.state.LoginCityName
  }

  Object.assign(config.data, _publicParam)

  return config
}, error => {
  Promise.reject(error)
})

// 添加响应拦截器
service.interceptors.response.use(
  response => {
    if (response.config.showLoading) {
      // tryHideFullScreenLoading()
    }
    var res = response.data
    // 以下请根据后端返回具体格式修改!!!!!
    if (res.success) {
      return res
    } else {
      if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
        Toast(res.msg)
      } else {
        Message({
          message: res.msg,
          type: 'error',
          duration: 5 * 1000
        })
      }
      return Promise.reject(res)
    }
  },
  error => {
    // 错误处理
    // tryHideFullScreenLoading()
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      Toast(error.message)
    } else {
      Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000
      })
    }
    return Promise.reject(error)
  }
)

// 加载封装
let loading
function startLoading () {
  loading = Loading.service({
    lock: true,
    text: '加载中',
    background: 'rgba(0, 0, 0, 0.7)'
  })
}
// 关闭加载
function endLoading () {
  loading.close()
}
let needLoadingRequestCount = 0
export function showFullScreenLoading () {
  if (needLoadingRequestCount === 0) {
    startLoading()
  }
  needLoadingRequestCount++
}

export function tryHideFullScreenLoading () {
  if (needLoadingRequestCount <= 0) return
  needLoadingRequestCount--
  if (needLoadingRequestCount === 0) {
    endLoading()
  }
}

export default service
