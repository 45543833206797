/*
 * @名称: 车辆信息
 * @Descripttion: ml 创建
 * @Author: ml
 * @Date: 2019-12-16 13:43:01
 * @LastEditors: ml
 * @LastEditTime: 2019-12-20 10:24:01
 */


import request from '@/utils/request'

/**
 * 车辆信息
 * @param {*} params 
 */
export function GetVehicleInfoById(params) {
  return request({
    url: "/Vehicle/Vehicle/GetVehicleInfoById",
    method: 'post',
    // headers: data.headers,
    data: params,
  })
}

/**
 * 车辆分享信息
 * @param {*} params 
 */
export function ShareVehicleInfoById(params) {
  return request({
    url: "/Vehicle/Vehicle/ShareVehicleInfoById",
    method: 'post',
    // headers: data.headers,
    data: params,
  })
}


/**
 * 车辆预览信息
 * @param {*} params 
 */
export function PreviewVehicleInfoById(params) {
  return request({
    url: "/Vehicle/Vehicle/PreviewVehicleInfoById",
    method: 'post',
    // headers: data.headers,
    data: params,
  })
}

/**
 * 报告预览信息
 * @param {*} params 
 */
export function GetDetectionReport(params) {
  return request({
    url: process.env.VUE_APP_Service_API + "SaveDetection/detection_storage/GetDetectionReport",
    method: 'post',
    // headers: data.headers,
    data: params,
  })
}

